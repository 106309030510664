import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/behandlung-eines-reizdarms"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
            IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
            </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331 active-trail">
                      <a href="/diagnose-reizdarm/" className="active-trail">
                        Diagnose Reizdarm
                      </a>
                    </li>
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div id="block-menu-menu-reizdarm" className="block block-menu">
                  <h2>Reizdarm</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/diagnose-reizdarm/" title>
                          Diagnose Reizdarm (IBS)
                        </a>
                      </li>
                      <li className="leaf active-trail">
                        <a
                          href="/behandlung-eines-reizdarms/"
                          title
                          className="active-trail active"
                        >
                          Behandlung eines Reizdarms
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/reizdarm-ernahrung/" title>
                          Ernährung bei Reizdarm
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-20" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    ><h4 style={{"font-size":"30px",
                     "line-height":"26px", "margin":"0px 0 0px 0", "font-family": "Open Sans, sans-serif"}}>                     
                        HABEN SIE
                        <br />
                        FRAGEN?
                      </h4>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        &nbsp;
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        Wenden Sie sich an unsere Experten.
                      </div>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "0px 0",
                          "line-height": "18px"
                        }}
                      >
                        <p>
                          <a
                            className="georgia arrow_01"
                            href="/expertenrat/"
                            target="_self"
                          >
                            Jetzt informieren
                          </a>
                        </p>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Behandlung eines Reizdarms{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="headimg border">
                      <img
                        typeof="foaf:Image"
                        src="/sites/default/files/header_visuals_695x286_diagnose_reizdarm.jpg"
                        width="695"
                        height="286"
                      />{" "}
                    </div>{" "}
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Behandlung und Therapie
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Wenn Sie nach einer ärztlichen Diagnose wissen, dass
                            Ihren Magen-Darm-Problemen ein Reizdarm zugrunde
                            liegt, kann dies sehr erleichternd sein. Denn mit
                            der Gewissheit, was die Ursache Ihrer unangenehmen
                            Beschwerden ist, können Sie beginnen, dem Reizdarm
                            gezielt entgegenzuwirken. Hierbei ist der erste,
                            wichtigste Schritt herauszufinden, was die Ursache
                            Ihrer Symptome ist.{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h3>Tipp:</h3>
                                <p>
                                  Halten Sie in einem Tagebuch die Speisen, die
                                  Sie zu sich genommen haben, die Ereignisse des
                                  Tages und die Reizdarm-Symptome fest. Oftmals
                                  verstärkt gerade eine Kombination von
                                  Ernährung und Stress die Beschwerden.
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <h3>
                                  Änderung Ihrer Lebensweise und Ernährung
                                </h3>
                                <ul>
                                  <li>
                                    <strong>
                                      Bauen Sie Stress in Ihrem Leben ab
                                    </strong>
                                    <br />
                                    Dazu ist es nicht erforderlich Ihr
                                    Berufsleben völlig umzukrempeln. Wichtig
                                    ist, zu lernen, übermäßigen{" "}
                                    <a href="/hohe-arbeitsbelastung/">
                                      Stress aus Ihrem Arbeitsalltag
                                    </a>{" "}
                                    zu verbannen. Dazu müssen Sie nicht gleich
                                    eine andere berufliche Laufbahn einschlagen.
                                    Achten Sie z.B. darauf, genügend Pausen zu
                                    machen, nicht zu viele Überstunden
                                    anzusammeln und sich regelmäßig bewusst Zeit
                                    für Sie selbst zu nehmen.
                                    <br />
                                    &nbsp;
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <strong>Treiben Sie mehr Sport</strong>
                                    <br />
                                    Regelmäßiger, maßvoller Sport kann Ihnen
                                    dabei helfen, Stress abzubauen und sich
                                    positiv auf Ihr Verdauungssystem auswirken.
                                    <br />
                                    &nbsp;
                                  </li>
                                  <li>
                                    <strong>
                                      Unterstützen Sie Ihre Verdauung mit einer
                                      ausgewogenen, ballaststoffreichen
                                      Ernährung
                                    </strong>
                                    <br />
                                    Vermeiden Sie die Nahrungsmittel, die Ihre
                                    Beschwerden auslösen oder verstärken – auch
                                    fettige und stark gewürzte{" "}
                                    <a href="/reizdarm-ernahrung/">
                                      Speisen können Ihre Darmfunktion
                                      beeinträchtigen
                                    </a>
                                    .<br />
                                    &nbsp;
                                  </li>
                                  <li>
                                    <strong>
                                      Essen Sie kleine Portionen auf mehrere
                                      Mahlzeiten verteilt
                                    </strong>
                                    <br />
                                    Achten Sie darauf, wenig und dafür öfter zu
                                    essen. Unregelmäßiges Essen und große
                                    Mahlzeiten können Ihre Beschwerden
                                    verstärken.
                                    <br />
                                    &nbsp;
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <h3>
                                  Medikamentöse Behandlung von
                                  Reizdarmbeschwerden
                                </h3>
                                <ul>
                                  <li>
                                    <p>
                                      Lassen Sie sich von Ihrem Arzt oder Apotheker beraten, um die für Sie am besten geeignete Behandlung zu finden.
                                    </p>
                                  </li>
                                </ul>
                                <h3>Alternative Therapien</h3>
                                <ul>
                                  <li>
                                    <p>
                                      <strong>
                                        Lernen Sie aktive Entspannungsmethoden
                                      </strong>
                                      <br />
                                      Meditation und Yoga helfen dabei, Stress
                                      abzubauen und das eigene Wohlbefinden zu
                                      steigern. Bewusste und regelmäßige
                                      Entspannungsübungen können helfen, auch
                                      Ihren ,gereizten‘ Darm zu beruhigen.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      <strong>
                                        Holen Sie sich den Rat eines Experten
                                      </strong>
                                      <br />
                                      Sprechen Sie mit einem Experten zum Thema
                                      Stress. Ein verbessertes Stressmanagement
                                      kann sich positiv auf Ihren Reizdarm
                                      auswirken. Möglichweise hilft Ihnen auch
                                      Unterstützung in Form einer Hypnotherapie
                                      oder eine Psychotherapie weiter.
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf active-trail">
                    <a
                      href="/behandlung-eines-reizdarms/"
                      title
                      className="active-trail active"
                    >
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reisedurchfall-infos-und-fakten/" title>
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbta11"
        />
      </div>
    );
  }
}

export default Page;
